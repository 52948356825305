import logo from './img/gastax.png';
import './custom.scss';
import { 
  ThemeProvider, Container, AppBar, Toolbar, Typography, Box, Button, Stack, Divider, ImageList, ImageListItem,
  Item, Card
} from "@mui/material";
import { createTheme as createMuiTheme} from '@mui/material/styles';

//import { Carousel, GameImage } from "./components/Carousel/Carousel"
import etbhImg from './img/ETBH_gsax.png';
import btImg from './img/BT_logo_gsax.png';
import shmovilleImg from './img/shmoville_gsax.png';
import adventureCalendarImg from './img/AdventureCalendar_gsax.png';

function App(props) {
  const SLIDESHOWHEIGHT = 80
  return (
    <Container>
      <h1>Our Games</h1>
      <Card style={{
        padding: '16px',
        marginTop: '5%',
        borderRadius: '8px 8px 0px 0px'
      }}>
        <h3>Nothing to See Here…Yet!</h3>
        <div>
<p>Throughout our journey at Game Studio Åland, we've had the privilege of bringing imaginative worlds to life on the Roblox platform. Our games, woven from the threads of creativity and community feedback, have been the cornerstone of our shared adventures. While these titles have been dear to our hearts, we've made the thoughtful decision to retire them from Roblox, paving the way for new and exciting ventures ahead.</p>

<p>Currently, our portfolio is in a phase of transformation. As we transition between the chapters of our story, we are refocusing our creative energies and preparing to dive into our next big project. Though our library is momentarily quiet, the silence buzzes with the anticipation of innovation and the birth of new experiences.</p>

{/*
<p>We are thrilled to announce that our next venture will be "John Doe," a game that promises to bring the quaint charm and subtle complexities of everyday life into the spotlight. Set in the vibrant and ever-evolving town of Shmoville, "John Doe" invites players to step into the shoes of an everyday character, navigating the intricacies of daily life, forging relationships, and making decisions that weave their unique narrative thread in the tapestry of Shmoville.</p>

<p>While "John Doe" is still under development, we are committed to creating an experience that resonates with the authenticity of life, the richness of choice, and the warmth of a community. Stay tuned for updates and sneak peeks as we bring the world of "John Doe" from the realm of imagination into reality.</p>
*/}

<p>
  Thank you for your continued support and enthusiasm. Your journey with us, from our past titles on Roblox to the vibrant landscapes of our future games, is the true heartbeat of Game Studio Åland. 
  Cherishing the stories we've shared, as we eagerly anticipate the ones we've yet to craft!
</p>
        </div>
      </Card>
    </Container>    
  );
}

export default App;
