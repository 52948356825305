import logo from './img/gastax.png';
import './custom.scss';
import { 
  ThemeProvider, Container, AppBar, Toolbar, Typography, Box, Button, Stack, Divider, ImageList, ImageListItem,
  Item
} from "@mui/material";
import { createTheme as createMuiTheme} from '@mui/material/styles';
import { Outlet, Link } from "react-router-dom";


const darkTheme = createMuiTheme({
  palette: {
    mode: 'dark',
  },
});

function Page(props) {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        bgcolor: '#000',
        color: 'text.primary',
        borderRadius: 1,
        p: 3,
        padding: 0
      }}
    >
      {props.children}
    </Box>
  );
}

function MenuButton(props) {
  return (
    <Button sx={{ color: '#0066ae' }}>{props.children}</Button>
  );
}

function Footer(props) {
  return (
    <Container {...props} sx={{
      align: 'center',
      width: '100vw',
      height: '128px'
    }} />
  );
}

const CONTENTHEIGHT = 75

function Layout() {
  return (
    <ThemeProvider theme={darkTheme}>
      <Page>
        <Stack
          direction='column'
          spacing={0}
          sx = {{
            maxHeight: '100vh',
            overflow: 'hidden'
          }}
        >
          <AppBar position="static" sx={{width:'100vw',bgcolor: '#000'}}>       
              <Toolbar disableGutters sx={{width:'100vw'}}>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ mr: 2, display: {xs: 'none',md: 'flex', padding:'8px'}} } 
                > 
                  <img src={logo} alt="Game Studio Åland"/>
                </Typography>
                <Box>
                  <Link to="/"><MenuButton>News</MenuButton></Link>
                  <Link to="/games"><MenuButton>Our Games</MenuButton></Link>
                  <Link to="/about"><MenuButton>About Us</MenuButton></Link>
                  <Link to="/contact"><MenuButton>Contact Us</MenuButton></Link>
                </Box>        
              </Toolbar>
          </AppBar>
          <Divider />
          
          <div style={{
            height: CONTENTHEIGHT + 'vh',
            maxHeight: CONTENTHEIGHT + 'vh',
            overflow: 'auto',
            background: '#000',
            position: 'relative',
          }}>
            <Outlet/>          
          </div>

          <Divider />
          <Box
              sx={{
                height: '50px',
                background: '#000'
              }}
          >
          </Box>
        </Stack>
      </Page>
    </ThemeProvider>
  );
}

export default Layout;
