import './custom.scss';
import { 
  Container, FormControl, Input, Stack, Button
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage  } from 'formik';


function App() {
  return (
    <Container>
      <Formik
        initialValues={{ name: '', email: '', message: '' }}
        validate={ values => {
          const errors = {}
          if(!values.email) {
            errors.email = 'Required'
          } else if( !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email) )
          {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting( true )
          let formData = new FormData()
          for(var key in values)
          {
            formData.append( key, values[key] )
          }
          fetch('https://www.gamestudio.ax/mailer/gamestudio/',{
            method: 'POST',
            body: formData
          }).then(() => {
            alert("Thank You!")
            resetForm({ values: ''})
            setSubmitting( false )
          }).catch((error) => {
            alert("Something went wrong")
            console.log( error )
            setSubmitting( false )
          })
        }}
      >
        {({ isSubmitting }) => (          
          <Form>
            <Stack spacing={2}>
              <Field as={Input} type="text" name="name" size="40" required placeholder="Your Name" />
              <Field as={Input} type="email" name="email" size="40" required placeholder="Your Email" />
              <ErrorMessage name="email" component="div" />
              <Field as={Input} multiline name="message" cols="3" rows="10" placeholder="Your Message" required/>
              <Button type="submit" disabled={isSubmitting} variant="contained">Submit</Button>            
            </Stack>
          </Form>
        )}
      </Formik>
    </Container>    
  )
}

export default App;
