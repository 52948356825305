import logo from './img/gastax.png';
import './custom.scss';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import './App.css';
import { 
  Paper, Divider, Container,
  //List, ListItem, ListItemAvatar, ListItemText,
  List, ListItem, ListItemAvatar,
  Item, Card, Avatar, Chip
} from "@mui/material";
import { createTheme as createMuiTheme} from '@mui/material/styles';

import DadImg from './img/Dad_OfFire.png';
import AggaImg from './img/Bear_OfFire.png';
import WilliamImg from './img/williamiscoolerthau.png';
import MomImg from './img/Mom_OfFire.png';

function FunFacts( props ) {
  return (<></>)
  /*
  return (
    <div style={{marginTop: '8px'}}>
      <strong>{props.label}:</strong> 
      {props.children.map((e,i) => { 
        return ( <Chip key={i} label={e} style={{marginLeft:'2px', marginBottom: '2px'}}/>)
      })}
    </div>
  )
  */
}

function ListItemText( props )
{
  return (
    <div className="MuiListItemText-root MuiListItemText-multiline css-1xar93x">
      <span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-yb0lig">
        {props.label}
      </span>
      <div className="MuiTypography-root MuiTypography-body2 MuiListItemText-secondary css-11q8n1y">
        {props.children}
      </div>
    </div>
  )
}

function Member( props ) {
  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar alt={props.label} src={props.image} sx={{
            width: 64,
            height: 64
          }}/>
        </ListItemAvatar>
        <div>
          <ListItemText
            label={ props.label }
          >
            <strong>{props.role}</strong><br />
            {props.children}
            {props.interests ? <FunFacts label="Interests">{props.interests}</FunFacts> : ''}
            {props.favouritegames ? <FunFacts label="Favourite Games">{props.favouritegames}</FunFacts> : ''}
          </ListItemText>
        </div>
        <div>
        </div>
      </ListItem>
      <Divider/>
    </>
  );
}


function App() {
  return (
    <Container>
      <Card style={{
        padding: '16px',
        marginTop: '5%',
        borderRadius: '8px 8px 0px 0px'
      }}>
        <div>
          Greetings from the Åland Islands! 🏡 
          We are a family of gamers who just love games! ❤ Well, at least three of us are gamers. Mom just has to go along with it 😉
          We play a lot and also create our own games. 

          Our kids have grown up immersed in the world of Roblox, and therefore it came naturally to craft a few experiences there. 
          Unfortunately, Roblox doesn't extend its support to our beloved Åland Islands, so we're going to continue our game development journey on another platform.
        </div>
      </Card>
      <List sx={{width: '100%', bgcolor: 'background.paper'}}>         
        <Member 
          label="Dad_OfFire" 
          image={DadImg} 
          role="Group Owner" 
          interests={['languages', 'writing books', 'programming', 'cars & car design', 'legos']}
          favouritegames={['Unreal Tournament','Gran Turismo','Civilization','Tetris']}
        >          
          A gaming and programming veteran who coded his first game back in the 1980s.
          Dad works as a Systems Developer in the daytime and creates games in his sparetime.
          It is Dad who does most of the job programming our games.
        </Member>
        <Member 
          label="Mom_OfFire" 
          image={MomImg} 
          role="Producer"
          interests={['cuisine','pets','well-being','chill music','dancing','watching TV']}
        >
          Mom hardly plays any games herself, but she encourages us and pushes us to create games.
          Plus she cooks amazing food and provides us with clean clothes and just about anything we need. 
          We couldn't do any of the stuff we do without Mom.
          We love you! <span style={{color:'#f00'}}>❤❤❤</span>
        </Member>
        <Member 
          label="BjornKung a.k.a. Bear_OfFire" image={AggaImg} role="Game Creator"
          interests = {[ 'programming', 'cruise ships', 'history', 'geography', 'languages', 'math' ]}
          favouritegames={['Euro Truck Simulator 2','Uncharted','Red Dead Redemption','Far Cry']}
        >
          Björn is the one who started playing Roblox and creating games in Roblox Studio.
          Then Dad and William have followed 🙂 
          Björn has got a vast technical knowledge in how to create games on Roblox, and 
          can often teach even Dad a few tricks.
          Björn loves cruise ships.
        </Member>
        <Member 
          label="williamiscoolerthau a.k.a. Will_OfFire" 
          image={WilliamImg} 
          role="Game Tester"
          interests = {[ 'Roblox', 'super heros', 'YouTube', 'legos', 'math' ]}
          favouritegames={['Fortnite','Minecraft','Brookhaven','Geometry Dash']}
        >
          William was born a gamer! He is a quick learner and can teach the rest of us how to solve just about any game.
          He has a strong opinion about what makes a good game, and also comes up with ideas of his own, which makes him 
          an excellent game tester.
        </Member>
      </List>
      <Card style={{
        padding: '16px',
        borderRadius: '0px 0px 8px 8px'
      }}>
        Footnote: The images above are our Roblox avatars. Images may differ in appearance from the actual persons. 😉
      </Card>
    </Container>    
  )
}

export default App;
