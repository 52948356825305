import logo from './img/gastax.png';
import './custom.scss';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import './App.css';
import { 
  Paper, Divider, Container,
  //List, ListItem, ListItemAvatar, ListItemText,
  List, ListItem, ListItemAvatar,
  Item, Card, Avatar, Chip
} from "@mui/material";
import { createTheme as createMuiTheme} from '@mui/material/styles';

import DadImg from './img/Dad_OfFire.png';
import AggaImg from './img/Bear_OfFire.png';
import WilliamImg from './img/williamiscoolerthau.png';
import MomImg from './img/Mom_OfFire.png';

function FunFacts( props ) {
  return (
    <div style={{marginTop: '8px'}}>
      <strong>{props.label}:</strong> 
      {props.children.map((e,i) => { 
        return ( <Chip key={i} label={e} style={{marginLeft:'2px', marginBottom: '2px'}}/>)
      })}
    </div>
  )
}

function ListItemText( props )
{
  return (
    <div className="MuiListItemText-root MuiListItemText-multiline css-1xar93x">
      <span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-yb0lig">
        {props.label}
      </span>
      <div className="MuiTypography-root MuiTypography-body2 MuiListItemText-secondary css-11q8n1y">
        {props.children}
      </div>
    </div>
  )
}

function Member( props ) {
  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar alt={props.label} src={props.image} sx={{
            width: 64,
            height: 64
          }}/>
        </ListItemAvatar>
        <div>
          <ListItemText
            label={ props.label }
          >
            <strong>{props.role}</strong><br />
            {props.children}
            {props.interests ? <FunFacts label="Interests">{props.interests}</FunFacts> : ''}
            {props.favouritegames ? <FunFacts label="Favourite Games">{props.favouritegames}</FunFacts> : ''}
          </ListItemText>
        </div>
        <div>
        </div>
      </ListItem>
      <Divider/>
    </>
  );
}


function App() {
  return (
    <Container>
      <h1>News</h1>
      <Card style={{
        padding: '16px',
        marginTop: '5%',
        borderRadius: '8px 8px 0px 0px'
      }}>
        <h3>[2024-02-04] Goodbye Roblox!</h3>
        <div>
          <p>As we evolve in our game development journey, we find ourselves at a juncture of reflection and anticipation. Game Studio Åland has been a vessel of creativity and joy, crafting unique experiences within the vibrant world of Roblox. Our adventure has been rich with learning, filled with the laughter of our children growing up amidst pixels and scripts, and warmed by the community that has been with us every step of the way.</p>
          <p>While the winds of change are upon us, our passion for game development remains unaltered. Due to limitations in support for the Åland Islands within the Roblox platform, we are transitioning our focus to new horizons. This is not a farewell to the worlds we've built but an expansion of our creative canvas.</p>
          <p>Our sons, the spirited architects of their own realms, will continue to forge their paths within the Roblox community, embracing the joy of creation as a personal endeavor. As for us, we're excited to embark on a fresh chapter, exploring new platforms where our creativity can roam freely and our stories can unfold in new dimensions.</p>
          <p>We carry forward the spirit of innovation and the cherished memories from our time on Roblox. Game Studio Åland is more than a name; it's a testament to our journey, a nod to our roots, and a beacon for our future endeavors in the vast universe of game development.</p>
          <p>Thank you for sharing in our story thus far. We invite you to join us as we step into new worlds of imagination and possibility at gamestudio.ax. Here's to the next chapter, to new adventures, and to the endless potential of creating together.</p>
        </div>
      </Card>
    </Container>    
  )
}

export default App;
