import React from 'react';
import { createRoot } from "react-dom/client";
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';

import Layout from './Layout';
import OurGames from './OurGames';
import News from './News';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>          
          <Route index element={<News />}/>
          <Route path="games" element={<OurGames />}/>
          <Route path="about" element={<AboutUs />}/>
          <Route path="contact" element={<ContactUs />}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const container = document.getElementById("root");
const root = createRoot(container)
root.render(  
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);

/*
ReactDOM.render(
  <React.StrictMode>
    <App height={80}/>
  </React.StrictMode>,
  document.getElementById('root')
);
*/

/*
const root = ReactDOM.createRoot( document.getElementById('root') );
root.render(<App />);
*/